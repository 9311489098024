@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'actnow.css';
@import 'ag-grid-community/styles/ag-grid.css';

@import 'ag-grid-community/styles/ag-theme-alpine.css';
/* ///////////////////////////////Start Custom Css///////////////////////////////////////////////*/
h6 {
    margin-top: .5rem !important;
}

.active-menu {
    background-color: #312F2F !important;
    color: white !important;
}

.btn-success {
    color: #fff;
    background-color: #312F2F;
    border-color: #312F2F;
    border-radius: 30px;
    padding: 5px 15px 5px 15px;
}

    .btn-success:focus {
        color: #fff;
        background-color: #ed1b2f !important;
        border-color: #ed1b2f !important;
    }

    .btn-success:hover {
        color: #fff;
        background-color: #702c91 !important;
        border-color: #702c91 !important;
    }

    .btn-success:active {
        color: #fff;
        background-color: #702c91 !important;
        border-color: #702c91 !important;
    }

    .btn-success.disabled, .btn-success:disabled {
        color: #fff;
        background-color: #312F2F !important;
        border-color: #312F2F !important;
    }



.block {
    text-align: center;
    vertical-align: middle;
}

.text-primary {
    color: #ed0181 !important;
}

.circle {
    background: white;
    border: #ed0181 1.5px solid;
    border-radius: 200px;
    color: #8ebf47;
    height: 150px;
    font-weight: bold;
    width: 150px;
    display: table;
    margin: 20px auto;
    text-align: center;
}

    .circle h2 {
        vertical-align: middle;
        display: table-cell;
        color: #8ebf47;
    }

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
    background-color: #312F2F !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
    color: #ffffff !important;
}

.break-all {
    word-break: break-all !important;
}

.table td, .table th {
    border-bottom: 0px solid #dcdcdc !important;
}

.table > :not(:first-child) {
    border-top: 2px solid #dcdcdc !important;
}

.table th {
    border: 0px !important;
    color: #20396f !important;
    background: transparent !important;
    border-top: 2px solid #20396f !important;
    border-bottom: 2px solid #20396f !important;
    border-right: 0px;
    border-left: 0px;
}

input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 7px;
    left: 0px;
    font-size: 15px;
    color: rgb(153,153,153);
    border: none;
    outline: none;
    display: none;
}

.button-wrap {
    position: sticky;
    margin-left: 10px;
}

.new-button {
    padding: 7px 16px;
    cursor: pointer;
    border-radius: 15px;
    background-color: #ed0181;
    font-size: 13px;
    color: #fff;
    margin-left: -7px;
    margin-top: 0px;
}

    .new-button:hover {
        background-color: #312F2F;
        color: #fff;
    }

.form-control1 {
    padding: .30rem .75rem !important;
    border: 1px solid #312F2F;
    border-radius: 30px;
}

.mat-form-field-infix {
    border-top: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 24px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #000 !important;
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
    color: #ed0181 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #20396f;
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1.5em !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    opacity: 100 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px !important;
}

input[type='radio']:checked:before {
    background: #80c342 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    border-radius: 25px !important;
    border: #312F2F 1px solid !important;
    color: transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: transparent !important;
}

.mat-calendar-body-selected {
    background-color: #312F2F !important;
    color: white;
}

.mat-input-element {
    color: black !important;
}

.mm-active {
    color: #ffffff !important;
}

.side-menu-active {
    background-color: #312F2F;
    color: #ffffff !important;
}

.text-right {
    text-align: right !important;
}

#sidebar-menu ul li ul.sub-menu li a {
    color: #000000 !important;
}

.mat-expansion-panel-header {
    background-color: white !important;
    /*background-color: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);*/
}

.mat-expansion-panel-content {
    margin-bottom: 10px !important;
    border: #cacaca 1px solid !important;
    background: #ffffff;
}

.mat-expansion-panel-body {
    padding: 10px 24px 16px !important;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
    /*box-shadow: none !important;*/
}

a:hover {
    color: #312F2F;
}

.modal-body {
    padding: 0px !important;
}

.close {
    height: 20px;
    width: 20px;
    background-color: #ed1b2f !important;
    border-radius: 50%;
    display: inline-block;
    float: right;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: 1;
    cursor: pointer;
}

.counter {
    height: 30px;
    width: 30px;
    background-color: #ed1b2f !important;
    border-radius: 50%;
    display: inline-block;
    float: right;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
    text-shadow: none;
    opacity: 1;
    line-height: 2;
}

.side-bar {
    background: #ed1b2f;
}


.mat-expansion-panel {
    /*background: #ed1b2f;*/
    background: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
}

.mat-drawer-inner-container {
    overflow: hidden !important;
}

.main-logo {
    margin-left: 15px;
    width: 200px;
}

.side-menu .mat-icon {
    color: #131f86;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    /*background: rgb(240, 16, 130) !important;*/
    background: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
    color: white !important;
}

.form-center {
    margin: 0 auto;
    width: 50%
}

.button-center {
    text-align: -webkit-center;
}

.btn-primary {
    color: #fff;
    background-color: #312F2F !important;
    border-color: #312F2F !important;
    border-radius: 30px;
    padding: 5px 15px 5px 15px;
}

    .btn-primary:hover {
        color: #fff;
        background-color: #702c91 !important;
        border-color: #702c91 !important;
        border-radius: 30px;
        padding: 5px 15px 5px 15px;
    }

.text-grey {
    color: #505d69;
}

.text-bg {
    background-color: #ececec;
    padding: 5px;
}

.bg-pattern {
    background-image: url("assets/images/bg.jpg");
    background-attachment: fixed;
    background-size: cover;
}





.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #312F2F;
    background-color: #312F2F;
}

.form-group-custom .form-control:valid + label {
    -webkit-transform: translate(-.75em,-96%) scale(.8);
    transform: translate(-.75em,-96%) scale(.8);
    color: #312F2F;
}

.form-control {
    border: 1px solid #bbbbbb;
    border-radius: 30px;
}

.form-control-password {
    border-radius: 20px !important;
    margin-top: 10px !important;
    margin-left: -60px !important;
    position: relative !important;
    color: #702c91 !important;
    width: 0% !important;
}

.form-control-separate {
    border-radius: 20px;
    margin-top: -2px;
    margin-left: 5px;
    position: relative;
    color: #e40076 !important;
}

.form-control-green {
    border-radius: 20px;
    margin-top: 10px;
    margin-left: -55px;
    position: relative;
    color: #312F2F !important;
}

.header-profile-user {
    height: 36px;
    width: 36px;
    background-color: #ffffff;
    padding: 2px;
}

body[data-topbar=colored] #page-topbar {
    background-color: #ed0181;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.page-title-box2 {
    padding: 24px 12px 65px 12px;
    background-color: #702c91;
    margin: 0 -12px;
    background-size: auto;
    padding-top: 50px;
    padding-bottom: 124px;
}

.page-title-box {
    padding: 24px 12px 65px 12px;
    background-color: #702c91;
    margin: 0 -12px;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 124px;
}

.page-content {
    padding: 0px !important;
}

.page-title-box .breadcrumb .breadcrumb-item.active {
    color: white;
}

body[data-topbar=colored] .dropdown.show .header-item {
    background-color: #ed0181;
}

.vertical-menu {
    background: #fff;
}

.footer {
    background-color: #f4f8f9;
}

.metismenu li {
    border-bottom: #d7d7d7 1px solid;
}

#sidebar-menu ul li a {
    color: black;
}

.page-content-wrapper {
    margin-top: -100px;
}

a {
    color: #702c91;
}



.a:not([href]) {
    color: #e40076 !important;
}

.badge-soft-success {
    color: #312F2F;
    background-color: rgb(243, 255, 226);
}

.img-radius {
    background-color: #f3ffe2;
    padding: 5px;
    border-radius: 50px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
    color: #000000;
}

.nav-tabs-custom .nav-item .nav-link:after {
    background: #312F2F;
}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #ffffff;
    background-color: #312F2F;
}

.nav-tabs-custom .nav-item .nav-link {
    border: none;
    background-color: #fbfbfb;
}

.text-colr {
    color: #ed1b2f;
}

.text-green {
    color: #312F2F;
}

.header-title {
    font-size: 15px;
    color: #ed0181;
}

.col-form-label {
    text-align: left;
    font-weight: 700;
    line-height: 1.2 !important;
}

label {
    font-weight: 700;
    font-size: 16px;
    /*color: #ed0181;*/
    margin-top: .5em;
}

.form-group {
    margin-bottom: 0rem;
}

hr {
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.cursorPointer {
    cursor: pointer;
}


#sidebar-menu ul li ul.sub-menu li a:hover {
    color: white !important;
}

#sidebar-menu ul li .mm-active ul.sub-menu li {
    color: white !important;
}

#sidebar-menu ul li a {
    font-size: 13px !important;
}


.nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #312F2F !important;
    border-color: white !important;
}

.tab-content > .active {
    margin-top: 30px !important;
}

.nav-tabs .nav-link {
    background-color: #ee5aa6 !important;
    color: white !important;
    margin-left: 3px !important;
}

.payment-card {
    border: 1px solid #cac8c8;
    text-align: center;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #312F2F;
}

.modal-footer {
    border-top: none !important;
}

body {
    /*background-color: #f7f7f7;*/
}


#page-topbar {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.refresh-icon {
    background-color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 1px 3px 0px 4px;
    color: #312f2f;
}

.sub-icon {
    width: 15px;
}

.btn-dull:hover {
    color: #ffffff;
    background: #312f2f;
    text-decoration: none;
}

.btn-dull.focus, .btn-dull:focus {
    color: #ffffff;
    background: #312f2f;
    text-decoration: none;
}

.tab-box {
    background: #E9EAED;
    width: 100px;
    margin-right: 10px;
}

.vertical-menu, #page-topbar {
    z-index: 999 !important;
}

.btn-design {
    border-radius: 50%;
    background-color: #702c91;
    color: white;
    border: 1px solid #702c91;
    padding-top: 5px;
    margin-left: 8px;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 99999 !important;
}

.no-radius {
    border-radius: 0px !important;
}

/* ///////////////////////////////End Custom Css///////////////////////////////////////////////*/

