

body {
    font-family: "Helvetica Neue", Helvetica, Arial, "sans-serif";
    width: 100%;
    margin: auto;
    background: #dfe2dd59;
}

.mat-tab-group {
    font-family: "Segoe UI",Tahoma,Geneva,Verdana,sans-serif;
}

@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url('/AkzidenzGroteskBE-Regular.eot');
    src: url('/AkzidenzGroteskBE-Regular.eot?#iefix') format('embedded-opentype'), url('/AkzidenzGroteskBE-Regular.woff2') format('woff2'), url('/AkzidenzGroteskBE-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AkzidenzGrotesk-Light';
    src: url('/AkzidenzGroteskBE-Light.eot');
    src: url('/AkzidenzGroteskBE-Light.eot?#iefix') format('embedded-opentype'), url('/AkzidenzGroteskBE-Light.woff2') format('woff2'), url('/AkzidenzGroteskBE-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AkzidenzGrotesk-Med';
    src: url('/AkzidenzGroteskBE-Md.eot');
    src: url('/AkzidenzGroteskBE-Md.eot?#iefix') format('embedded-opentype'), url('/AkzidenzGroteskBE-Md.woff2') format('woff2'), url('/AkzidenzGroteskBE-Md.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AkzidenzGrotesk-Bold';
    src: url('/AkzidenzGroteskBE-Bold.eot');
    src: url('/AkzidenzGroteskBE-Bold.eot?#iefix') format('embedded-opentype'), url('/AkzidenzGroteskBE-Bold.woff2') format('woff2'), url('/AkzidenzGroteskBE-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.font-ag {
    /*font-family: 'AkzidenzGrotesk';
   font-family: 'Segoe UI';*/
    font-weight: 400;
}

.font-ag-light {
    /*font-family: 'AkzidenzGrotesk-Light';
    font-family: 'Segoe UI';*/
    font-weight: 400;
}

.font-ag-light-sized {
    /*font-family: 'AkzidenzGrotesk-Light';
    font-family: 'Segoe UI';*/
    font-size: 1.3rem;
}


.font-ag-medium {
    font-family: 'AkzidenzGrotesk-Med';
}

.font-ag-bold {
    /*font-family: 'AkzidenzGrotesk-Bold';*/
    font-weight: 700;
}

.fw-normal {
    font-weight: normal;
}

.strong {
    font-weight: bold;
}

.h2, h2 {
    font-size: 2rem;
}


.cursor-pointer {
    cursor: pointer !important;
}

.cursor-auto {
    cursor: auto !important;
}

.custom-select {
    height: 45px;
    background: #ffffff url(/themes/custom2010/ActNow/images/select-dropdown.jpg) no-repeat top right;
    background-size: 45px 100%;
}

.custom-card-header {
    height: 20px;
    background: url(/themes/custom2010/ActNow/images/card-header.jpg);
    background-size: 100% 20px;
}

.actnow-navbar {
    /*padding: 20px;*/
    z-index: 2000;
}

    .actnow-navbar:hover,
    .actnow-navbar:active {
        background: rgba(255,255,255,.95);
        color: black;
    }

        .actnow-navbar:hover .light-logo,
        .actnow-navbar:active .light-logo {
            display: none;
        }


        .actnow-navbar:hover .dark-logo,
        .actnow-navbar:active .dark-logo {
            display: block !important;
        }

        .actnow-navbar:hover .text-white,
        .actnow-navbar:active .text-white {
            color: #20396f !important;
        }

    .actnow-navbar .navbar-toggler {
        border: 1px solid #fff;
    }

    .actnow-navbar:hover .navbar-toggler,
    .actnow-navbar:active .navbar-toggler {
        border: 1px solid #20396f;
    }

    .actnow-navbar .navbar-toggler > i {
        color: #fff;
        font-size: 28px;
    }

    .actnow-navbar:hover .navbar-toggler > i,
    .actnow-navbar:active .navbar-toggler > i {
        color: #20396f;
    }

.nav-link:hover {
    color: #20396f !important;
}

.logo-text {
    font-size: 70%;
    /*text-align: center;*/
}

.actnow-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 5%;
    padding-left: 5%;
}

@media (min-width: 576px) {
    .actnow-container {
        width: 85%;
    }
}

.px-5pc {
    padding-right: 5%;
    padding-left: 5%;
}

.px-6pc {
    padding-right: 6%;
    padding-left: 6%;
}

@media (max-width: 991.9px) {
    .navbar-collapse {
        background: rgb(255 255 255 / 95%) !important;
        z-index: 100;
        padding: 10px;
    }

        .navbar-collapse > ul > li > a {
            color: #20396f !important;
            border-bottom: #20396f solid 1px;
        }
}

.home-banner-img {
    /*background: url('/themes/custom2010/ActNow/images/banner.png') center;*/
    background: url('/themes/custom2010/ActNow/images/GDPRCertFrontPage.jpg?v=<?php echo $__version; ?>') center;
}

.gdpr-intermediate-banner-img {
    background: url('assets/images/website/gdpr_intermediate_R_1.jpg') center;
}

.home-banner2-img {
    /*background: url('/themes/custom2010/ActNow/images/banner.png') center;*/
    background: url('assets/images/loginlogo.png') center;
}

.home-banner3-img {
    background: url('/themes/custom2010/ActNow/images/FOIFrontPage.jpg?v=<?php echo $__version; ?>') center;
}

.gdpr-banner-img {
    background: url('/themes/custom2010/ActNow/images/gdpr.jpg?v=<?php echo $__version; ?>') center;
}

.eu-gdpr-banner-img {
    background: url('/themes/custom2010/ActNow/images/eu-gdpr.jpg?v=<?php echo $__version; ?>') center;
}

.support-banner-img {
    background: url('/themes/custom2010/ActNow/images/s_s.jpg?v=<?php echo $__version; ?>') center;
}

.uae-banner-img {
    background: url('/themes/custom2010/ActNow/images/uae-webinar.jpg?v=<?php echo $__version; ?>') center;
}

.foi-banner-img {
    background: url('/themes/custom2010/ActNow/images/FOICertPageNew.jpg?v=<?php echo $__version; ?>') center;
}

.foisa-banner-img {
    background: url('/themes/custom2010/ActNow/images/foisa.jpg?v=<?php echo $__version; ?>') center;
}

.news-banner-img {
    background: url('/themes/custom2010/ActNow/images/news.jpg?v=<?php echo $__version; ?>') center;
}

.testimonial-banner-img {
    background: url('/themes/custom2010/ActNow/images/dpo-cert.jpg?v=<?php echo $__version; ?>') center;
}

.team-banner-img {
    background: url('/themes/custom2010/ActNow/images/team.jpg?v=<?php echo $__version; ?>') center;
}

.mtg-banner-img {
    background: url('/themes/custom2010/ActNow/images/MindTheGap03.jpg?v=<?php echo $__version; ?>') center;
}

.iom-banner-img {
    background: url('/themes/custom2010/ActNow/images/iom.jpg?v=<?php echo $__version; ?>') center;
}

.international-law-banner-img {
    background: url('/themes/custom2010/ActNow/images/InternationalLaw.jpg?v=<?php echo $__version; ?>') center;
}

.inhouse-banner-img {
    background: url('/themes/custom2010/ActNow/images/InhouseTrainingNew.jpg?v=<?php echo $__version; ?>') center;
}

.live-webinar-banner-img {
    background: url('/themes/custom2010/ActNow/images/live-webinars.jpg?v=<?php echo $__version; ?>') center;
}

.eu-fullday-banner-img {
    background: url('/themes/custom2010/ActNow/images/eu-fullday.jpg?v=<?php echo $__version; ?>') center;
}

.gdpr-fullday-banner-img {
    background: url('/themes/custom2010/ActNow/images/GDPRFullDayWorkshops.jpg?v=<?php echo $__version; ?>') center;
}

.ccpa-banner-img {
    background: url('/themes/custom2010/ActNow/images/webinar.jpg?v=<?php echo $__version; ?>') center;
}

.phone-a-friend-banner-img {
    background: url('/themes/custom2010/ActNow/images/phone.jpg?v=<?php echo $__version; ?>') center;
}

.about-banner-img {
    background: url('/themes/custom2010/ActNow/images/about.jpg?v=<?php echo $__version; ?>') center;
}

.dpo-cert-banner-img {
    background: url('/themes/custom2010/ActNow/images/UAEDPCert.jpg?v=<?php echo $__version; ?>') center;
}

.hello-dpo-banner-img {
    background: url('/themes/custom2010/ActNow/images/phone.jpg?v=<?php echo $__version; ?>') center;
}

.ripa-banner-img {
    background: url('/themes/custom2010/ActNow/images/ripa-banner.jpg?v=<?php echo $__version; ?>') center !important;
}

@media (max-width: 575.9px) {
    .actnow-banner {
        max-height: 600px !important;
        min-height: auto !important;
    }
}

.actnow-banner {
    background-size: cover;
    min-height: 900px;
}

.actnow-banner-content {
    line-height: 1;
    margin-top: 150px;
}

    .actnow-banner-content h2 {
        font-size: 3rem;
        margin-bottom: 0px;
        line-height: 1;
        text-align: center;
    }

@media (min-width: 576px) {
    .actnow-banner-content h2 {
        font-size: 6rem;
        text-align: left;
    }
}

.actnow-banner-content p {
    font-size: 1.5rem;
    text-align: center;
}

.actnow-banner-content a {
    font-size: 1rem;
    text-align: center;
}

@media (min-width: 576px) {
    .actnow-banner-content p,
    .actnow-banner-content a {
        text-align: left;
    }
}

.actnow-3-categories {
    margin-top: 1.5rem;
}

@media (min-width: 576px) {
    .actnow-3-categories {
        margin-top: -144px;
    }
}


.actnow-our-services > div {
    background: url('/themes/custom2010/ActNow/images/hot-air-balloon.jpg?v=<?php echo $__version; ?>');
    /*background-position: left -1120px;*/
    background-size: cover;
    background-repeat: no-repeat;
}

.actnow-our-services h1 {
    font-size: 2.5rem;
}

@media (min-width: 576px) {
    .actnow-our-services h1 {
        font-size: 5rem;
    }
}


.actnow-upcoming-courses > div {
    background: url('/themes/custom2010/ActNow/images/upcoming-courses-bg.jpg?v=<?php echo $__version; ?>');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}

.actnow-upcoming-courses h1 {
    font-size: 2.5rem;
}

@media (min-width: 576px) {
    .actnow-upcoming-courses h1 {
        font-size: 5rem;
    }
}

.about-us-width {
    width: 100%;
}

@media (min-width: 576px) {
    .about-us-width {
        width: 60%;
    }
}

@media (min-width: 576px) {
    .company-logos {
        height: 315px;
    }
}


.bg-gradient {
    background: url('/themes/custom2010/ActNow/images/gradient-bg.jpg');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 576px) {
    .bg-sm-white {
        background-color: #fff !important;
    }

    .bg-sm-light {
        background-color: #f8f9fa !important;
    }
}

@media (min-width: 768px) {
    .bg-md-white {
        background-color: #fff !important;
    }

    .bg-md-light {
        background-color: #f8f9fa !important;
    }
}

@media (min-width: 576px) {
    .vertical-text {
        bottom: 0;
        display: inline-block;
        font-weight: 400;
        height: 20px;
        margin-top: 9rem;
        padding: 0;
        text-align: left;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        white-space: nowrap;
        width: 20px;
        font-size: 1.2rem;
        letter-spacing: 2px;
    }
}

.vertical-text {
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
}

    .vertical-text i {
        font-size: 1.5rem;
    }

    .vertical-text a {
        padding-top: 9rem
    }

.text-main {
    color: #9d257e;
}

.text-notification {
    color: #9d257e !important;
}

.text-second, a {
    color: #20396f !important;
}

a {
    /*font-weight: bold;*/
}

.text-third {
    color: #9c277a;
}

.text-gray {
    color: #59595b;
}

.bg-gray {
    background: #59595b;
}

.bg-main {
    background: #9d257e;
}

.bg-blue-select {
    color: #fff !important;
    background-color: #20396f !important;
}

.pt-2, .py-2 {
    padding-top: 2rem !important;
}

.pt-6, .py-6 {
    padding-top: 4.5rem !important;
}

.pt-7, .py-7 {
    padding-top: 6rem !important;
}

.pt-8, .py-8 {
    padding-top: 7rem !important;
}

.pt-5-5, .py-5-5 {
    padding-top: 5.5rem !important;
}

.pt-9, .py-9 {
    padding-top: 9rem !important;
}

.pt-10, .py-10 {
    padding-top: 10.5rem !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.mt-6, .my-6 {
    margin-top: 4.5rem !important;
}

.mt-7, .my-7 {
    margin-top: 6rem !important;
}

.mt-8, .my-8 {
    margin-top: 7.5rem !important;
}

.mt-9, .my-9 {
    margin-top: 9rem !important;
}

.mt-10, .my-10 {
    margin-top: 10.5rem !important;
}

.p-6 {
    padding: 4.5rem !important;
}

.p-7 {
    padding: 6rem !important;
}

.p-8 {
    padding: 7.5rem !important;
}

.p-9 {
    padding: 9rem !important;
}

.p-10 {
    padding: 10.5rem !important;
}

.bg-gray-gradient {
    background: linear-gradient(145deg, rgb(255 255 255) 0%, rgb(208 209 211) 100%);
}
/* #1a376f to #862b78 145 degrees */
.bg-purple-gradient {
    background: rgb(26,55,111);
    background: -moz-linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
    background: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a376f",endColorstr="#862b78",GradientType=1);
}

/* #e5a430 to #f9dd45 145 degrees */
.bg-yellow-gradient {
    background: rgb(229,164,48);
    background: -moz-linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
    background: linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5a430",endColorstr="#f9dd45",GradientType=1);
}
/* #5381b3 to #1a3870 145 degrees */
.bg-blue-gradient {
    background: rgb(83,129,179);
    background: -moz-linear-gradient(145deg, rgba(83,129,179,1) 0%, rgba(26,56,112,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(83,129,179,1) 0%, rgba(26,56,112,1) 100%);
    background: linear-gradient(145deg, rgba(83,129,179,1) 0%, rgba(26,56,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5381b3",endColorstr="#1a3870",GradientType=1);
}

.bg-red-gradient {
    background: linear-gradient(145deg, var(--bs-red) 0%, rgba(134,43,120,1) 100%);
}


.bg-pink-gradient {
    background: linear-gradient(145deg, var(--bs-pink) 0%, rgba(134,43,120,1) 100%);
}

.news-card-body {
    height: 400px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.endorsement-title {
    font-size: 2rem;
}

@media (min-width: 768px) {
    .endorsement-title {
        font-size: 3rem;
    }
}

.tutor-card-body {
    height: 360px;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
}

.tutor-card-detail {
    background: #f9fafc;
    text-align: center;
}

    .tutor-card-detail .blue-divider {
        width: 100%;
    }

.tutor-desc {
    width: 100%;
}

@media (min-width: 768px) {
    .tutor-card-detail {
        text-align: left;
    }

    .tutor-desc {
        width: 60%;
    }

    .tutor-card-detail .blue-divider {
        width: 80%;
    }
}

.tutor-list > .splide__slide {
    background: black;
}

    .tutor-list > .splide__slide > .row {
        opacity: 0.5;
    }

    .tutor-list > .splide__slide.is-active {
        background: transparent !important;
    }

        .tutor-list > .splide__slide.is-active > .row {
            opacity: 1 !important;
        }

.tutor-splide .splide__pagination {
    bottom: -5% !important;
}

.blue-divider {
    border: 1px solid #20396f;
    float: left;
}


.actnow-nav-pills {
    /*font-family: 'AkzidenzGroteskBE-Bold';*/
    font-weight: 700;
}

    .actnow-nav-pills .nav-link {
        color: #59595b;
    }

        .actnow-nav-pills .nav-link.active, .actnow-nav-pills .show > .nav-link, .actnow-nav-pills .nav-link:hover {
            color: #9d257e !important;
            background: none !important;
            border-bottom: 2px solid #9d257e !important;
            border-radius: 0;
        }

.actnow-course-quiz > div {
    background: url('/themes/custom2010/ActNow/images/actnow-course-quiz.png');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}

.actnow-course-quiz h1 {
    font-size: 2.5rem;
}

@media (min-width: 576px) {
    .actnow-course-quiz h1 {
        font-size: 4rem;
    }
}



.robot-height {
    height: 675px !important;
}

.actnow-robot > div {
    background: url('/themes/custom2010/ActNow/images/yellow-robot.png');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    height: -webkit-fill-available;
}

.actnow-robot h1 {
    font-size: 2.5rem;
}

@media (min-width: 576px) {
    .actnow-robot h1 {
        font-size: 4rem;
    }
}


.actnow-course-right-side-box {
    margin-top: 1.5rem;
    /*display: none;*/
}

.course-side-news {
    display: none;
}

.course-side-search {
    display: none;
}

@media (min-width: 1200px) {
    .actnow-course-right-side-box {
        display: block;
    }

    .course-side-news {
        display: block;
    }

    .course-side-search {
        display: block;
    }
}

@media (min-width: 1200px) {
    .actnow-course-right-side-box {
        margin-top: -80px;
    }
}


.d-block-important {
    display: block !important;
}

.d-none-important {
    display: none !important;
}

.splide__pagination__page.is-active {
    background: #000 !important;
}

.splide--ttb > .splide__pagination {
    bottom: 98% !important;
    left: 100% !important;
}

.splide__slide {
    overflow: hidden;
    text-overflow: ellipsis;
}

.testimonial-quote {
    /*max-height: 15rem;*/
    overflow: hidden;
    text-overflow: ellipsis;
}

.testimonial-closing-quote {
    display: none;
}

@media (min-width: 1400px) {
    .testimonial-closing-quote {
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

.btn-outline-default {
    color: #20396f;
    border-color: #20396f;
}

    .btn-outline-default:not(:disabled):not(.disabled).active, .btn-outline-default:not(:disabled):not(.disabled):active, .show > .btn-outline-default.dropdown-toggle {
        color: #fff;
        background-color: #9d257e;
        border-color: #9d257e;
    }

    .btn-outline-default:hover {
        color: #fff;
        background-color: #9d257e;
        border-color: #9d257e;
    }


/* ACT NOW Coding*/

.icon-right-margin {
    margin: 0px 20px 0px 0px;
}

.size-info {
    font-size: 52px;
}


.scrollbar-padding {
    padding-right: 25px;
}

.an-scroll::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}


.an-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(104, 140, 240, 0.3);
}

.an-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(134,43,120,1);
    outline: 1px solid slategrey;
}

.news-min-width {
    max-width: 100%;
}

.txt-align {
    text-align: left !important;
}

.footer-content-size {
    font-size: smaller;
}

.width-80 {
    width: 80% !important;
}

.input-updates {
    width: 250px;
    padding: 2px;
    margin: -5px 0px 12px 0px;
}

.a-underline {
    text-decoration: underline !important;
}

.heading-color {
    color: #20396f !important;
}

.fw-400 {
    font-weight: 400;
}

.fw-300 {
    font-weight: 300;
}



.d-none-important {
    display: none !important;
}

.one-day-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/course-bg.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.inhouse-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/library.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.bookings-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/course-bg.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.uae-fullday-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/uae-course.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.gdpr-e-learning-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/gdpr-e-learning.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.gdpr-essential-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/gdpr-essential.png?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.adv-cert-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/adv-cert.png?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.news-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/fcig.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.gdpr-handbook-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/AllHandbookPages.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.policy-pack-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/Project.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.led-policy-pack-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/ledpolicypack.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.helpline-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/helpline.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.contact-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/phone.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.fcig-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/FoundationCertificateinIGUK.jpg?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.uae-fcig-banner-img {
    background: url('/themes/custom2010/ActNow/images/beach-way.jpg?v=<?php echo $__version; ?>') center;
}

.ccpa-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/ccpa.jpg') center;
    background-size: cover;
}

.gdpr-banner {
    height: 75vh;
    min-height: 600px;
    background: url('/themes/custom2010/ActNow/images/banner.png?v=<?php echo $__version; ?>') center;
    background-size: cover;
}

.single-banner {
    height: 75vh;
    min-height: 600px;
    background-size: cover;
}

.list-inside {
    list-style: inside !important;
}

.text-main {
    color: #9d257e;
}

.text-notification {
    color: #9d257e !important;
}

.content-list {
    margin: -15px 0px 20px 30px;
}


.angle-down {
    /*width: 10px !important;*/
    float: right !important;
    margin: 20px 0px 0px 10px;
}

.news-min-height {
    min-height: 100%;
}

.h-100 {
    height: 100% !important;
}


.main-banner-size {
    width: 100%;
    height: 100%;
}

.main-header {
    background: rgba(255,255,255,0.7);
    color: black;
}

.mt-minus-25 {
    /*margin-top: -25px;*/
}

.color-white {
    color: white !important;
}

.text-left {
    text-align: left !important;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.pull-center {
    text-align: center !important;
}

.p-bottom-5 {
    padding-bottom: 5px !important;
}


@media screen and (min-device-width: 1539px) {

    .course-details-day-portrait-only {
        display: none !important;
    }
}

@media (max-width: 1538px) {
    .course-details-day-no-portrait {
        display: none !important;
    }
}


@media (max-width: 1200px) {
    .no-portrait {
        display: none !important;
    }

    #tv_screen th {
        border: 2px solid hsl(0deg 0% 94%);
        font-size: 17px !important;
        padding: 5px;
    }

    .screen-heading {
        font-size: 45px !important;
    }

    .screen-header {
        height: 65px !important;
    }
}

@media screen and (min-device-width: 1201px) {

    .portrait-only {
        display: none !important;
    }
}

@media screen and (min-device-width: 1024px) {
    body::before {
        /*content: "1024px or higher";*/
    }
}

@media screen and (min-device-width: 1366px) {
    body::before {
        /*content: "1366px or higher";*/
    }
}

@media screen and (min-device-width: 1440px) {
    .main-banner-size {
        width: 100%;
        height: 100%;
    }

    .pt-5-5, .py-5-5 {
        padding-top: 5.5rem !important;
    }

    .actnow-3-categories {
        /*margin-top: -165px;*/
    }

    .p-4 {
        padding: 1.5rem !important;
        /*margin-left: -90px;
        margin-right: -80px;*/
    }

    .extra-grad-padding {
        padding-left: 27% !important;
    }

    .outside-box {
        position: absolute;
        left: 0;
        float: left;
        width: 56%;
    }


    .n_heading {
        position: absolute;
        bottom: 160px;
        left: 15px;
    }

    .n_h_size {
        font-size: 2.5rem;
    }
}

@media screen and (min-device-width: 1920px) {
    .main-banner-size {
        width: 100%;
        height: 100%;
    }

    .actnow-3-categories {
        /*margin-top: -210px;*/
    }

    .p-4 {
        padding: 1.5rem !important;
        margin-left: 0px;
        margin-right: 0px;
    }

    .extra-grad-padding {
        padding-left: 24% !important;
    }

    .outside-box {
        position: absolute;
        left: 0;
        float: left;
        width: 63%;
    }



    .n_heading {
        position: absolute;
        bottom: 100px;
        left: 15px;
    }

    .n_h_size {
        font-size: 3rem;
    }
}



.splide--ttb > .splide__pagination {
    display: flex;
    flex-direction: column;
    bottom: 50%;
    left: auto;
    right: 0.5em;
    transform: translateY(100%);
}


.transparent {
    color: transparent;
}

.min-height-international-p {
    min-height: 60px;
}

.dubai-img {
    max-height: 350px;
}

.btn-auto {
    width: auto;
}

.boxes-size {
    position: absolute;
    width: -webkit-fill-available;
}

.right-panel-width {
    width: 375px !important;
}

.yellow-grad-ul {
    text-align: left;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}



/* Landscape*/

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .actnow-container {
        width: 85%;
    }

    .ipad-heading2 {
        font-size: 45px;
    }

    .tutor-min-height {
        min-height: 380px;
    }

    .pl-1 {
        padding-left: 1rem !important;
    }
}

/* Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .actnow-container {
        width: 85%;
    }


    .ipad-heading {
        padding: 0 !important;
        width: max-content;
        margin: 0px 0px 0px -55px !important;
    }

    .ipad-heading2 {
        width: max-content;
        margin-left: -130px;
        font-size: 45px;
    }



    .actnow-our-services > div {
        background: url(/themes/custom2010/ActNow/images/hot-air-balloon.jpg);
        /* background-position: left -1120px; */
        background-size: cover;
        background-repeat: no-repeat;
    }
}


.qts {
    height: 90px;
    width: 80px;
    max-width: 85px;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.menu-heading {
    font-size: 2.7em;
}

.dd-inner {
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, Tahoma, Geneva, Verdana, Arial, "sans-serif";
}


.other-link {
    text-decoration: none !important;
}

    .other-link:hover {
        color: hsl(339, 69%, 38%) !important;
        text-decoration: none !important;
        background-color: transparent;
        cursor: pointer;
    }


.book-btn {
    background-color: #f8f9fa;
    text-decoration: none;
    padding: 6px 20px;
    display: inline-block;
    font: bold 12px arial, sans-serif;
    color: #1a376f;
    border: solid 1px #1a376f;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
}

    .book-btn:hover {
        text-decoration: none;
        /*background: rgb(198,28,60);*/

        background: linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%) !important;
        color: white;
    }


    .book-btn.disabled, .book-btn:disabled, fieldset:disabled .book-btn {
        pointer-events: none;
        opacity: .65;
    }

.reading-link {
    padding-top: 10px;
}


.spkr_img {
    width: 180px !important;
    /* margin-bottom: 10px; */
    height: 185px !important;
}

.online-course-heading {
    font-weight: 600 !important;
}
/* END ACT NOW Coding*/

.tutor-description-height {
    height: 380px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.plus-vat {
    font-size: x-large;
}

.download-flyer {
    font: bold 15px/21px arial,sans-serif;
    text-align: center;
    margin: 20px 0px -40px 0px;
}


/* upcoming dates table */

.full {
    background: #ccc;
    cursor: default;
}

.l {
    border-bottom: solid 1px black;
    padding: 7px 0;
}

.d {
    text-align: center;
    width: 110px;
    font-weight: bold;
}


.stream-heading {
    font-size: 2rem;
}

.black-bg {
    background-color: black !important;
}

.book-bg {
    background-color: white !important;
}

.course-table {
    width: 100%;
    margin: 5px;
    border-bottom: solid 2px #1a376f;
    font: normal 14px/21px arial, sans-serif;
}

    .course-table td {
        padding: 6px 3px;
        border-bottom: solid 1px #000;
    }

.course {
    text-decoration: none;
    font-weight: bold;
    color: #1a376f;
}

    .course:hover {
        text-decoration: none;
        color: rgb(198,28,60);
    }


.banner-logo {
    width: 100px;
    float: left;
    margin-right: 25px;
    margin-top: 10px;
}

.grey-icon {
    width: 120px;
}

.crs-right {
    width: 180px;
}


.landing-banner {
    padding-top: 55px;
}


.menu-width {
    width: 75% !important;
    padding-left: 3% !important;
    padding-right: 3% !important;
}


.about-menu {
    margin: 0px -6px 25px -5px;
    text-align: center !important;
}

.irms-blue {
    width: 150px;
}




@media only screen and (max-device-width: 480px) {
    .landing-banner {
        padding-top: 5px;
    }

    .menu-width {
        width: 100% !important;
        padding-left: 0%;
        padding-right: 0%;
    }


    .n_heading {
        position: absolute;
        bottom: 265px;
        left: 15px;
    }

    .n_h_size {
        font-size: 2rem;
    }

    .size-info {
        font-size: 34px;
    }

    .p-5 {
        padding: 1rem !important;
    }

    .p-6 {
        padding: 1.5rem !important;
    }


    .about-menu {
        margin: 0px 0px 0px 0px;
        text-align: left !important;
    }
}


.primary {
    /*background: #702c91;*/ /*icon color*/
    background: #9d257e; /*text color*/
    color: #fff;
}

.confirmation-header {
    margin: -24px -24px 0px -25px;
}

.modal-footer-button-margin {
    margin: -30px 0px -20px 0px;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pr-2 {
    padding-right: 2rem !important;
}

.footer {
    margin: auto;
    width: 100%;
    left: 0;
    padding: 0;
    position: inherit;
}

.footer-top {
    position: absolute;
    bottom: 0px;
    background: #b7b8ba;
    height: 400px;
    width: 400px;
    z-index: -10;
}

.page-header {
    margin: 25px 0px 0px 0px;
    background: #f3f4f3 !important;
    padding-right: 20px;
}

.page-header-second {
    background: #fff !important;
    height: 40px !important;
    margin: -40px 0px 0px 0px
}

.page-header-second2 {
    background: #fff !important;
    height: 92px !important;
    margin: -92px 0px 0px 0px;
}

.no-border {
    border: none !important;
}

.no-radius {
    border-radius: 0px !important;
}

.search-bg {
    background: #f9f9f9 !important;
    /*margin: 5px 33px -6px -4px;*/
}

.mt-minus-5 {
    margin-top: -5px;
}

.bg-second {
    background: #20396f;
}



.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
    border-radius: 4px;
    border: 0px solid #fff;
    /*margin: 11px 0px -10px -10px;*/
}

.mat-button-toggle-button {
    /*border: 0;
  background: none;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
  width: 100%;
  cursor: pointer;*/
    margin-top: -9px !important;
}

.mat-button-toggle-checked {
    border: 1px solid #c5c0c7;
    color: #fff !important;
    background-color: #20396f !important;
}

.mat-button-toggle {
    /*background-color: aliceblue;*/
    color: #000;
    margin: 0px 0px 0px 10px !important;
}

.mat-button-toggle {
    /*border: 1px solid #e8c3c3;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  height: 36px;
  font-size: 14px;
  font-style: bold;
  margin-top: -11px;*/
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 3px;
    height: 30px;
    /*border: 1px solid #f28628;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  width: 30px;
  height: 30px;
  font-family: 'Nunito Semibold', sans-serif;
  font-size: 14px;
  font-style: bold;*/
}

.mat-form-field {
    /* margin-top: 9px;*/
}

.mat-icon {
    display: inline-flex;
    vertical-align: text-top;
}

.tog-buttons {
    padding: 5px 20px 0px 0px;
}

.filter-apply {
    margin-top: 6px;
}

.an-logo {
    height: 60px;
}

.an-logo-parent {
    padding: 20px 20px 20px 20px;
    height: 100px !important;
}

.an-filter-parent {
    padding: 20px 20px 20px 20px;
    height: 80px !important;
}

.an-search {
    margin-top: -3px;
}

#layout-wrapper {
    background: white;
}

.mat-tab-nav-bar, .mat-tab-header {
    border-bottom: 0px solid !important;
}
/*
.ag-scrolls {
    height: auto !important;
}

.ag-body {
    position: relative !important;
    top: auto !important;
    height: auto !important;
}

.ag-header {
    position: relative !important;
}

.ag-floating-top {
    position: relative !important;
    top: auto !important;
}

.ag-floating-bottom {
    position: relative !important;
    top: auto !important;
}

.ag-bl-normal-east,
.ag-bl-normal,
.ag-bl-normal-center,
.ag-bl-normal-center-row,
.ag-bl-full-height,
.ag-bl-full-height-center,
.ag-pinned-left-cols-viewport,
.ag-pinned-right-cols-viewport,
.ag-body-viewport-wrapper,
.ag-body-viewport {
    height: auto !important;
}*/

.grid-wrapper {
    /*width: 100%;
    height: 100%;
    border: 1px solid red;*/
}

.pg-size {
    position: absolute;
    bottom: 60px;
    z-index: 9;
    left: 80px;
}

.grid-row {
    cursor: pointer !important;
}

.isCancelled {
    background-color: pink !important;
}

#myGrid {
    /*height: 100%;*/
}

/*.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    height: 50%;
}*/

.outer-div {
    background: white;
}

.bg-lg {
    background: #f3f4f3 !important;
}

.top-dd {
    background: #20396f;
    color: white;
    width: 100%;
    height: 55px;
    padding: 16px 10px 0px 10px;
}

.main-select-opt {
    height: 35px;
}

#mat-select-value-1 {
    color: white;
}

.h-crs-detail {
    height: 38px;
}

.mb-3px {
    margin-bottom: 3px !important;
}

.no-bg {
    background: none;
}

.no-border {
    border: 0;
}

.delegate-box {
    background: #20396f;
    color: white;
    font-size: larger;
    padding: 0px 5px 3px 5px;
}

.income-box {
    /*background: #c61c3c;
    color: white;
    font-size: x-large;
    padding: 10px;*/

    background: #c61c3c;
    color: white;
    font-size: larger;
    padding: 0px 5px 3px 5px;
}

.debt-red {
    /*background: #c61c3c;
    color: white;*/
    box-shadow: 0px 0px 0px 3px white inset;
    /* box-shadow: 0px 0px 0px 2px #4eff00 inset; */
    border-color: green;
    /* box-sizing: border-box; */
    border: 2px solid white;
    padding: 20px;
    border-color: red;
}

.cd-td {
}

.cd-span {
    background: white !important;
    display: block;
    height: 38px !important;
    /*width: 99.9%;
    clear: both*/
}

.span-height {
    height: 38px !important;
}

.td-date {
    min-width: 100px !important;
}

.mt-inner-dd {
    margin-top: 2px;
}

.lbl-wraper {
    max-width: 400px;
    min-height: 145px;
}

.lbl-notes {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.u-line {
    height: 1px !important;
    background-color: #ccc !important;
    border: none !important;
}

hr {
    height: 3px !important;
    background-color: #20396f !important;
    border: none !important;
    opacity: unset;
}

.dt-box-f {
    font-size: 15px;
    font-weight: 400;
}

.bg-green {
    background-color: #12a512;
}

.crs-size {
    font-size: 14px;
    padding-top: 7px;
}

.crs-size-max {
    font-size: 14px;
    line-height: 1.25;
}

.crs-margins {
    padding: 0px 5px 0px 0px;
    margin: -5px 0px 0px 5px;
}

.mt-loc-row {
    margin-top: -8px;
}


.download-icon {
    width: 50px;
}

.yearly-grid-min-height {
    min-height: 355px;
}

#main_dd:focus {
    background-color: #20396f !important;
}

#main_crs:focus {
    background-color: #20396f !important;
}
/*
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
*/

.tile-bg {
    background: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
}

.max-d-w {
    max-width: 60px !important;
}

.left-stats-box {
    margin-right: 25px;
    border-radius: 0px 30px 30px 0px;
}

.mr-booking-stats {
    margin-right: -35px;
}

.booking-stats {
    height: 50px;
    border-radius: 0px 15px 15px 0px;
    padding: 5px 15px 0px 15px;
}

.stats-figure-fs {
    font-size: 30px !important;
}

.left-stats-figure {
    font-size: 65px;
    font-weight: 700;
}

.stats-box-inner {
    /*border-radius: 125px;
     padding: 30px 0px 30px 0px; 
    width: 180px;
         margin: 0 auto;
    */
    height: 115px;
    position: relative;
    line-height: 130px;
}

.stats-box {
    border-radius: 125px;
    /* padding: 30px 0px 30px 0px; */
    width: 180px;
    height: 180px;
    margin: 0 auto;
    position: relative;
    line-height: 180px;
}

.stats-box-booking {
    width: 125px !important;
    height: 125px !important;
    line-height: 120px;
}

.stats-figure {
    font-size: 40px;
    font-weight: 700;
}

.bg-box-gray {
    background: #fdfdfd;
}

.week-courses-box {
    height: 445px;
    overflow-y: auto;
    overflow-x: hidden;
}

.section-heading {
    font-size: 3rem;
}

.h-auto {
    height: auto !important;
}

.revenue-lbl-width {
    width: 250px;
}

.transform-down {
    transform: translate(0,7px) !important;
    height: 18px !important;
}

.transform-up {
    transform: translate(0,-4px) !important;
    height: 18px !important;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
    background: linear-gradient(145deg, rgba(26,55,111,1) 0%, rgba(134,43,120,1) 100%);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: url();
}

.offer-parent {
    clear: both;
    text-align: center;
    font: normal 14px/21px arial, sans-serif;
    padding: 5px;
    margin: 10px;
    /*border: solid 1px red;
    background: #fee;*/
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.offer-price {
    color: red;
    font-weight: bold;
}

.accord-inner-heading {
    margin: 10px;
    text-align: center;
    font-weight: bold;
}

.hanbook-show-style {
    display: none;
    text-align: center;
    clear: both;
    font: normal 14px/21px arial, sans-serif;
    padding: 5px;
    margin: 10px;
    border: solid 1px #ccc;
    background: #eee;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.footprint-style {
    text-align: center;
    margin: 10px 0;
    padding: 10px;
    background: #cfc;
    color: #080;
    border: solid 1px #080;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font: normal 14px/21px arial,sans-serif;
}

.subscribe-style {
    border: 1px solid #ccc;
    background: #eee;
    font: normal 14px/21px arial, sans-serif;
    margin: 10px;
    padding: 5px;
    text-align: center;
}

.accept-style {
    text-align: center;
    font: normal 14px/21px arial, sans-serif;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: black;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #20396f !important;
}




.user-role {
    top: -6px;
    position: relative;
    margin-right: 10px;
}

.screen-bg {
    background-color: black;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 5px;
}

.screen-header-old {
    background: rgb(255 235 59);
    padding: 20px;
    height: 85px;
}

.screen-header {
    padding: 20px;
    height: 85px;
}

.screen-logo {
    width: 10%;
}

.screen-heading {
    font-size: 60px;
    font-weight: bold;
    margin-top: -20px;
    width: 45%;
    color: white;
    border-bottom: 3px solid #ffffff;
}

.screen-date {
    font-size: 50px;
    font-weight: bold;
    margin-top: -20px;
    width: 25%;
}

.screen-body {
    padding: 20px;
}

.scr_date {
    min-width: 140px;
}

.scr_title {
    color: /*rgb(255 235 59) !important;*/
    #F37021 !important;
}

.scr_title_w {
    width: 100% !important;
    text-align: left !important;
}

.scr_title_heading {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.scr_cancel {
    color: red !important;
}

.scr_day {
    min-width: 85px;
}

.scr_speaker {
    min-width: 120px;
}

.scr_initial {
}

.scr_material {
    max-width: 100px;
}

.scr_bookings {
    min-width: 60px;
}

.curr-date {
    width: 100%;
}

.menu_less {
    margin-top: -15px;
}

.show-header {
    font-size: 15px;
}

.hide-header {
    padding: 10px;
}

#tv_screen {
    border-collapse: collapse;
    border: 1px solid #69899F;
}

    #tv_screen tr {
        /*border: 1px dotted hsl(0deg 0% 94%);*/
        /*padding: 5px;*/
    }

    #tv_screen td {
        /*padding: 5px 10px 5px 5px;*/
        font-size: 24px;
        color: hsl(0deg 0% 94%);
    }

    #tv_screen tr:first-child {
        /*border-left: 0px solid hsl(0deg 0% 94%);*/
    }

    #tv_screen th {
        border: 2px solid hsl(0deg 0% 94%);
        font-size: 25px;
        padding: 5px;
    }


.tbl-screen {
    width: 100%;
    /*border: 1px solid #000000;*/
}

.th-screen {
    background-color: hsl(0deg 0% 94%);
}

.h-auto {
    height: auto;
}

.w-inherit {
    width: inherit;
}

.lbl-address {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}

.btn-holder {
    justify-content: flex-end;
    display: flex;
}

.booking-bg {
    background: white !important;
}

.not-paid {
    background: #c61c3c !important;
}

.red {
    background-color: #c61c3c !important;
}

.red_text {
    color: #c61c3c !important;
}

.green {
    background-color: green !important;
}

.grey {
    background-color: gray !important;
}

.gray_text {
    color: gray;
    text-align: center;
    padding: 0 0 5px 0;
}

.outstanding-green {
    background-color: #0080008c !important;
}

.amber {
    background-color: hsl(45deg 100% 50%);
}

.amber_text {
    color: hsl(45deg 100% 50%);
}


.paid {
    background: green !important;
}

.status-circles {
    /*width: 20px;
    height: 20px;
    margin-top: 10px;
    border-radius: 10px;
    display: block;
    text-align: center;
    margin: 0 auto;*/

    width: 20px;
    height: 20px;
    margin-top: 10px;
    border-radius: 10px;
    display: block;
    /* text-align: center; */
    /* margin: 0px auto; */
    margin-left: 20px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.panel {
    border: 1px solid #888;
    padding: 10px;
}

.green_text {
    color: green;
    text-align: center;
    padding: 0 0 5px 0;
}


.smallRadio .mat-radio-container {
    height: 10px;
    width: 10px;
}

.smallRadio .mat-radio-outer-circle {
    height: 10px;
    width: 10px;
}

.smallRadio .mat-radio-inner-circle {
    height: 10px;
    width: 10px;
}

.smallRadio .mat-radio-button .mat-radio-ripple {
    height: 20px;
    width: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
}

.smallRadio .mat-radio-label-content {
    font-size: 13px !important;
    font-weight: 100;
    user-select: auto;
    order: 0;
    line-height: inherit;
    padding-left: 5px !important;
    padding-right: 10px !important;
}

.prod-h {
    margin: 10px -10px 0px 0px !important;
    /*width: -webkit-fill-available;*/
}


/*@media print {*/
/* Hide everything in the body when printing... */
/*body.printing * {
        display: none;
    }*/
/* ...except our special div. */
/*body.printing #print-me {
        display: block;
    }
}

@media screen {*/
/* Hide the special layer from the screen. */
/*#print-me {
        display: block;
    }
}*/

.printing {
    display: none;
}

.w10 {
    width: 10% !important;
}

.w15 {
    width: 15% !important;
}

.w70 {
    width: 70% !important;
}

.non-printing {
    display: block;
}

.full-width {
    width: 100% !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    .print:last-child {
        page-break-after: avoid;
        page-break-inside: avoid;
        margin-bottom: 0px;
    }

    .w10 {
        width: 10% !important;
    }

    .w15 {
        width: 15% !important;
    }

    .w70 {
        width: 70% !important;
    }

    #section-to-print-office, #section-to-print-office *, #section-to-print, #section-to-print, #printSection_accountantReport * {
        visibility: visible;
    }

    #section-to-print-office, #section-to-print, #printSection_accountantReport {
        /*overflow: visible;*/

        position: relative !important;
        top: -1800px;
        left: 0;
    }

    .debt-red {
        print-color-adjust: exact;
    }

    .redborder {
        border: 2px solid red;
        border-radius: 50%;
        print-color-adjust: exact;
    }

    .red {
        color: red !important;
        print-color-adjust: exact;
    }

    .green {
        color: green !important;
        print-color-adjust: exact;
    }

    .amber {
        color: hsl(45deg 100% 50%);
        print-color-adjust: exact;
    }

    .page-break {
        break-after: page;
    }

    table {
        page-break-after: auto
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    td {
        page-break-inside: avoid;
        page-break-after: auto
    }

    thead {
        display: table-header-group
    }

    tfoot {
        display: table-footer-group
    }
    /*#logoDiv, #logoDiv * {
        visibility: visible;
    }*/

    /*#logoDiv {
        visibility: visible;
        position: relative !important;
        top: 0px;
        left: 0
    }

    #print-heading, #print-heading * {
        visibility: visible;
    }

    #print-heading {        
        position: relative !important;
        top: -38px;
        left: 0
    }*/

    .mat-tab-header {
        display: none;
    }

    .mat-tab-header {
        display: none;
    }

    .mat-tab-label-container {
        display: none;
    }

    .page-header-second {
        display: none;
    }

    .hide-for-print {
        display: none;
    }

    .card-body {
    }

    .bg-yellow-gradient {
        background: rgb(229,164,48);
        background: -moz-linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
        background: -webkit-linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
        background: linear-gradient(145deg, rgba(249,165,27,1) 0%, rgba(248,217,46,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5a430",endColorstr="#f9dd45",GradientType=1);
        print-color-adjust: exact;
    }

    .marketing-email-width {
        max-width: 25% !important; /* Adjust as needed */
        word-wrap: break-word !important; /* Allow breaking words */
        overflow-wrap: break-word !important; /* For wider browser compatibility */
        white-space: normal !important; /* Allow text to wrap to the next line */
        overflow: hidden !important; /* Prevent overflow */
        text-overflow: ellipsis !important; /* Add ellipsis for visual cue */
    }
}

/* Define a style rule to remove the borders */

.no-border .ag-cell {
    border: none;
}

.bordered-row .ag-cell {
    border: 1px solid #ccc;
}

.bordered-header .ag-header-cell {
    border: 1px solid #ccc;
}

.v-align-top {
    vertical-align: top;
}

.debt-red {
    background: #c61c3c;
    color: white;
    box-shadow: 0px 0px 0px 3px white inset;
    /* box-shadow: 0px 0px 0px 2px #4eff00 inset; */
    border-color: green;
    /* box-sizing: border-box; */
    border: 2px solid white;
    padding: 4px 5px 0px 0px !important;
    border-color: red;
}

.redborder {
    border: 2px solid red;
    border-radius: 50%;
    print-color-adjust: exact;
}

.redborder-simple {
    /*border: 2px solid red;*/
    box-shadow: 0px 0px 0px 2px red inset;
    /*border-radius: 50%;
    print-color-adjust: exact;*/
}

.debt-btn-min-width {
    width: 135px;
}

.f-15px {
    font-size: 15px;
}


/*
.card-flip {
    width: 200px;
    height: 200px;
    perspective: 1000px;
}

.card-course, .card-product {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-course {
    background-color: #f1c40f;
    transform: rotateY(0deg);
}

.card-product {
    background-color: #2ecc71;
    transform: rotateY(180deg);
}

.card-flip:hover .card-course {
    transform: rotateY(-180deg);
}

.card-flip:hover .card-product {
    transform: rotateY(0deg);
}
*/



/*.container {
    perspective: 1000px;*/ /* add perspective for 3D effect */
/*}

.front, .back {
    width: 200px;
    height: 200px;
    position: absolute;
    backface-visibility: hidden;*/ /* hide the backface of the div */
/*transition: transform 0.5s ease-in-out;*/ /* add transition effect */
/*}

.front {
    background-color: blue;
}

.back {
    background-color: red;
    transform: rotateY(180deg);*/ /* flip the back div */
/*}

.container:hover .front {
    transform: rotateY(180deg);*/ /* flip the front div */
/*}

.container:hover .back {
    transform: rotateY(0deg);*/ /* flip the back div back to its original state */
/*}*/




/*.vertical-text {
    display: inline-block;
    transform: rotate(-90deg);*/ /* rotate the div -90 degrees */
/*transform-origin: left top;*/ /* set the origin to the top left corner */
/*white-space: nowrap;*/ /* prevent text from wrapping */
/*font-size: 20px;*/ /* set font size as desired */
/*color: #000;*/ /* set text color as desired */
/*background-color: #fff;*/ /* set background color as desired */
/*padding: 5px;*/ /* add padding as desired */
/*}*/

.flip-btn {
    /*width: 50%;*/
    height: 40px;
    border-radius: 15px 15px 0px 0px;
}

.flip-btn-border {
    border: 0px solid #9d257e;
    border-bottom: 0px;
}

.flip-border-bottom-white {
    border-bottom: 3px solid #9d257e;
}

.p-rl-10px {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.submenu-btn {
    border-radius: 25px;
    padding: 2px 5px 4px 13px;
}


.information-icon {
    width: 125px;
}

.not-found {
    margin-top: 20px;
    position: absolute;
    font-weight: 600;
    margin-left: -60px;
    font-size: larger;
}

.verification-code-box {
    width: 75px;
    height: 75px;
    text-align: center;
    border-radius: 40px;
    font-size: 20px;
}


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    /*content: attr(data-title);
    background-color: #00FF00;
    color: #111;
    font-size: 150%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;*/

    content: attr(data-title);
    background-color: #cdcdcdad;
    color: #111111b0;
    /* font-size: 150%; */
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: -10%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    /* border: 1px solid #111111; */
    /* z-index: 99999; */
    visibility: hidden;
}

[data-title] {
    position: relative;
}

/*logout*/
.message-box {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.animated {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

#clockdiv {
    font-family: sans-serif;
    color: #fff;
    display: inline-block;
    font-weight: 100;
    text-align: center;
    font-size: 30px;
}

    #clockdiv div > span {
        padding: 15px;
        border-radius: 3px;
        background: #00816A;
        display: inline-block;
    }

    #clockdiv > div {
        padding: 10px;
        border-radius: 3px;
        background: #00BF96;
        display: inline-block;
    }

.smalltext {
    padding-top: 5px;
    font-size: 16px;
}

.btn.btn-lg, .btn-group-lg > .btn {
    font-size: 14px;
    padding: 12px 20px;
}

.btn-default-logout:hover, .btn-default-logout:focus, .btn-default-logout:active, .btn-default-logout.active, .open > .dropdown-toggle.btn-default-logout {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #333;
}

.btn-default-logout {
    background-color: #cc0000;
    border-color: #cc0000;
    color: #fff;
}

.btn-success-logout {
    color: #fff;
}

.message-box .mb-container .mb-middle .mb-content {
    width: 100%;
    float: left;
    padding: 10px 0px 0px;
}

.message-box .mb-container .mb-middle .mb-title {
    width: 100%;
    float: left;
    padding: 10px 0px 0px;
    font-size: 31px;
    font-weight: 400;
    line-height: 36px;
}

.message-box .mb-container .mb-middle {
    width: 50%;
    left: 25%;
    position: relative;
    color: #FFF;
}

.message-box .mb-container {
    position: absolute;
    left: 0px;
    top: 35%;
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    width: 100%;
}

.btn-default-logout {
    background-color: #cc0000;
    border-color: #cc0000;
    color: #fff;
}

.is-inhouse {
    background-color: antiquewhite !important;
}

.white-border {
    border: 1px solid white;
}

.row-border {
    border: 1px solid red;
}

.pr-10px {
    padding-right: 10px !important;
}

.cancelled-txt {
    color: gray !important;
}

.debt-toggle-button-span {
    font-size: 13px;
}

.debt-toggle-button {
    width: 95px;
}

.course-report {
    font-size: 11px;
}


.f-licences {
    font-size: small;
}

.fs-licences-circle {
    font-size: x-large;
}

.footer-date {
    font-size: 27px !important;
}

.footer-time {
    font-size: 29px !important;
}

.static-footer {
    position: fixed;
    left: 0;
    bottom: 0;
}

.no-gutters {
    --bs-gutter-x: none !important;
}

.screen-card {
    border-radius: 10px;
    min-height: 50px;
    margin-right: 15px;
    padding: 20px;
}

.width-20percent {
    width: 20% !important;
}

.screen-card-title {
    font-size: 27px;
    font-weight: bold;
}

.screen-card-level1 {
    color: #606060;
    font-weight: bold;
    font-size: 17px;
    padding: 5px;
}

.screen-card-level2 {
    color: #606060;
    font-size: 18px;
    padding: 5px;
}

.screen-card-border-bottom {
    border-bottom: 2px #b4b4b4 solid;
}

.screen-card-delegate-number {
    font-size: 30px;
    font-weight: 800;
}

.screen-card-status-symbol {
    width: 8px;
    height: 25px;
    border-radius: 10px;
    display: block;
    margin-left: 25px !important;
}

.screen-card-mt {
    margin-top: 10px;
}

.light-gray-color {
    color: #b4b4b4 !important;
}

.vl {
    border-left: 3px solid #b4b4b4;
    height: 28px;
    width: 1px;
    margin-top: 5px !important;
}

.screen-body-card {
    padding: 5px 5px 5px 10px;
    font-weight: bold;
    border-radius: 4px;
    color: black;
    width: 100% !important;
}

.screen-body-card-date {
    padding: 5px 5px 5px 3px;
    border-radius: 4px;
    color: black;
    width: 100% !important;
}

.screen-row {
    margin-top: 2px;
    margin-bottom: 2px;
}

.status-table {
    width: 170px;
}

.custom-table {
    border-collapse: separate !important;
    border-spacing: 10px 0; /* Adjust the spacing between borders */
    /*border: 2px solid #fff;*/ /* Optional: border for the table */
    /*border-collapse: collapse;
    width: 100%;*/
}

.td-custom-spacing {
    padding: 0px 10px 0px 10px;
}

.custom-spacing {
    border-bottom: 1px solid #fff; /* Border for table cells */
    padding: 8px 0px 8px 0px;
    text-align: center;
    /*padding: 10px;*/ /* Padding for table cells */
    /*margin-left: 10px;*/ /* Left margin */
    /*margin-right: 10px;*/ /* Right margin */
    /*content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;*/ /* Height of the border */
    /*background-color: #fff;*/ /* Color of the border */
    /*box-shadow: 0 1px 0 0 #fff;
    border: 2px solid #fff;*/ /* Border for table cells */
    /*padding: 10px;*/ /* Padding for table cells */
    /*border: 4px solid #fff;*/ /* Increase the border thickness */
    /*padding: 10px;*/ /* Padding for table cells */
}

.alert-height-auto {
    height: auto;
}

.alert-container {
    /*width: 600px;*/
    text-align: center;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    padding: 20px;
    border-radius: 8px;
}

.alert-title {
    font-size: 36px;
    margin: 0;
    font-weight: 800;
}

.alert-subtitle {
    font-size: 24px;
    color: #666;
    margin: 10px 0;
    font-weight: normal;
}

.alert-details {
    margin: 0px 0;
}

    .alert-details div {
        margin: 3px 0;
        padding-top: 10px;
    }

.alert-info-title {
    font-weight: bold;
    font-size: 20px;
}

.alert-info-text {
    font-size: 16px;
    color: #666;
    margin: 10px 0;
}

.alert-footer {
    margin-top: 40px;
    color: #666;
    font-size: 14px;
}

.alert-logo {
    width:125px;
}
